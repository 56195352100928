import React,{ useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { SearchListSm } from './SearchListSm';

export const SearchBarSm = ({serachClickedHandler,searchClicked,name,setName}) => {

    const searchDivRef = useRef(null);

    const [names,setNames]=useState([]);

    const navigate = useNavigate();

     const getSearchNames=(search)=>{

      const celeb_url = process.env.REACT_APP_CELEB;

      axios.get(`${celeb_url}/search?q=${search}`)
    .then(res=>{
         setNames(res.data);
    }).catch(error=>{
       console.log(error);
    });
      
     }

     const nameChangeHandler=(search)=>{

        setName(search);
         
         if(search.length<=1){
            setNames([]);
            return serachClickedHandler(false);
         }
         serachClickedHandler(true);
         getSearchNames(search.toLowerCase());
     }

     const redirectToSearch=(search)=>{
        if(search.length<=1)
        return;
        serachClickedHandler(false);
      //   search=encodeURIComponent(search);
      //  return navigate(`/search?q=${search.replaceAll("%20","+")}`);
     }

     const handleKeyDown = (event) => {
      if (event.key === 'Enter' && event.target.value.length>1){
        serachClickedHandler(false);
        const search=encodeURIComponent(name);
        return navigate(`/search?q=${search.replaceAll("%20","+")}`);
      }
      
  }

    const handleOutsideClick = (event) => {
        if (searchDivRef.current && !searchDivRef.current.contains(event.target)) {
            serachClickedHandler(false);
        }
      };
    
    
      useEffect(() => {
        
        // Attach event listener when the component mounts
        window.addEventListener('click', handleOutsideClick);
    
        // Detach event listener when the component unmounts
        return () => {
          window.removeEventListener('click', handleOutsideClick);
        };
      }, []);


    return (
        <Search>
                     <div className="col-12 mx-auto col-md-12 col-lg-12 search-div px-2">
                     
                     <div className="search">
                     
                     <div class="form-group">

                     <div className={"btn search-icon"} onClick={()=>redirectToSearch(name)} to={`/search?q=${encodeURIComponent(name).replaceAll("%20","+")}`}>
                         <FontAwesomeIcon className="fa-search icon" icon={faSearch}/>
                             </div>

                     <input class="form-control text-start" type="text" placeholder='Search'  name="name" value={name}
                       onChange={(e)=>nameChangeHandler(e.target.value)} onKeyDown={handleKeyDown}/>

                         </div>

                     </div>

                     </div>

                     <div ref={searchDivRef} className='search-list-div col-12'>
                     { (names && names.length>=1 && searchClicked) && <SearchListSm names={names} redirectToSearch={redirectToSearch}/> }
                     </div>

        </Search>
    );
}

const Search = styled.div`

position:releative;

.search-list-div{
    position:absolute;
    z-index:1111;
    padding: 0 2rem 0 0.5rem;
}

.search .form-control{
    border:none;
    border: 0.1rem transparent;
    font-size:0.9rem;
    font-family: 'Karla', sans-serif;
    background: var(--lightDark2);
    box-shadow: none;
    text-align:left;
    padding: 0.5rem 0;
    position: relative;
    padding-left:5rem;
    color: var(--lightGrey) !important;
    opacity:0.7;
   
}


.search .form-group input:focus{
    border: 0.1rem transparent;
    background: var(--lightDark2);
    color: var(--mainWhite) !important;
    opacity:1;
}

.search .icon{
   font-size:1.2rem;
   color: var(--mainWhite);
}

.search-icon{
    position:absolute;
    top:4.56rem;
    //left:30rem;
   // right: 40rem;
   // margin-right:20rem;
    background-color: var(--lightOrange);
    padding:0.39rem 1.4rem;
    border-radius: 0.2rem 0 0 0.5rem;
    cursor: pointer !important;
    z-index:1111;
}

.form-control::placeholder {
    color: var(--lightGrey);
    opacity:0.8;
  }

  `;